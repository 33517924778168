import React from 'react';
import { theme } from '@theme';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Heading, Text, TextMono } from '@atoms';
import { ConversionPanelContainer, Content, ContentFlexWrapper, CTAContainer } from "./styles/ConversionPanelStandard.styled"
import ConversionPanelReference from './ConversionPanelRerference';
import ButtonWrapper from './ConversionPanelBtnWrapper';
import { graphql, useStaticQuery } from 'gatsby'
import OptimizedImage from '../../utils/optimizedImage';

const ConversionPanelStandard = ({ component }) => {
  const {
    textAlign,
    callsToAction,
    subheading,
    heading,
    kicker,
    reference,
    background,
    noTopPadding,
    noBottomPadding,
  } = component

  const backgroundData = useStaticQuery(graphql`
    query ConversionPanelBackgroundQuery {
      contentfulComponentImage(id: {eq: "1fbd679a-cf7e-5bd2-84b1-0e5591a6f000"}) {
        __typename
        id
        primaryImage {
          gatsbyImageData
          file {
            url
          }
          description
        }
      }
    }
  `)

  const backgroundImage = backgroundData?.contentfulComponentImage?.primaryImage

  const bgColorObj = {
    'light': theme.colors.offWhiteColor,
    'dark': theme.colors.primary,
    'alternate': theme.colors.offWhite,
    'white': theme.colors.white,
  }

  const variant = (bg) => {
    if (bg === 'light') {
      return 'dark';
    } else if (bg === 'dark') {
      return 'light';
    } else if (bg === 'white') {
      return 'white';
    } else {
      return 'light';
    }
  };

  const kickerColor = (compBg) => {
    if (compBg === 'light' || compBg === 'white') {
      return theme?.colors?.primary;
    } else if (compBg === 'dark') {
      return '#FFFFFF';
    } else {
      return 'light';
    }
  }

  return (
    <ConversionPanelContainer
      data-comp={ConversionPanelStandard.displayName}
      className='flex'
      noTopPadding={noTopPadding}
      noBottomPadding={noBottomPadding}
      style={{
        display: 'flex',
        width: '100%',
        alignItems: `${textAlign === 'left' || reference ? 'flex-start' : 'center'}`,
        minHeight: '250px',
        background: `${background === 'light' || background === 'white' ? bgColorObj[background] : 'rgba(0, 45, 194, 0.5)'}`,
        backgroundPositionX: `100%`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backdropFilter: `${background === 'dark' ? 'blur(25px)' : 'none'}`,
      }}
    >
      {background === "dark" &&
        <OptimizedImage className="background" image={backgroundImage?.gatsbyImageData} src={backgroundImage?.file?.url} alt="" />
      }
      <Content
        reference={reference}
        alignItems={textAlign}
      >
        <ContentFlexWrapper
          ctaCount={callsToAction?.length}
          textAlign={reference ? 'left' : callsToAction?.length > 1 ? 'center' : textAlign}
          reference={reference}
        >
          <div>
            {kicker &&
              <TextMono pb={3} style={{
                color: kickerColor(background)
              }}>
                {kicker}
              </TextMono>
            }
            {heading &&
              <Heading
                headingSize={component?.headingSize}
                background={background}
              >
                {heading}
              </Heading>
            }
            <div id='conversion-panel--space-bar' style={{ width: '32px', height: '16px' }} />
            {subheading?.raw &&
              <Text pt={3} pb={7} textVariant={variant(background)} style={{ fontSize: '22px', lineHeight: '30px' }}>
                {documentToReactComponents(JSON.parse(subheading?.raw))}
              </Text>
            }
          </div>
          <CTAContainer
            ctaCount={callsToAction?.length}
          >
            {callsToAction && callsToAction.map((cta, index) => (
              <ButtonWrapper key={index} cta={cta} background={background} />
            ))}
          </CTAContainer>
        </ContentFlexWrapper>
        {reference &&
          <ConversionPanelReference reference={reference} variant={variant} background={component?.background} />
        }
      </Content>
    </ConversionPanelContainer>
  );
};

ConversionPanelStandard.displayName = 'ConversionPanelStandard';

export default ConversionPanelStandard
